import React, { useState } from 'react'
import { Button } from 'reactstrap'
import Copied from '../../../../Components/Copied/Copied'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default ({ shareData, copyOnly, ...rest }) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const { title, text, url } = shareData
  // const dontShare = copyOnly || false

  const shareAvailability = navigator.share

  const handleCopy = (e) => {
    setCopySuccess(true)
    setTimeout(function () {
      setCopySuccess(false)
    }, 2000)
  }

  return (
    <>
      <Copied snapToMy='right' visible={copySuccess} />
      <CopyToClipboard text={url || text}
        onCopy={() => {
          handleCopy()
        }}
      >
        <Button variant="secondary"><i className="fas fa-copy"></i></Button>
      </CopyToClipboard>
      {shareAvailability ?
        <Button
          onClick={() => {
            navigator.share({ title, text, url })
              .then(() => {
                console.log('Thanks for sharing!');
              })
              .catch((err) => {
                console.error(err)
              })
          }}
          {...rest}
        >
          <i className="fas fa-share-alt"></i>
        </Button>
        :
        <>
        </>

      }
      

    </>
  )
}