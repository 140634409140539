import axios from 'axios'

export const apiAxios = axios.create()

const projectName = 'picdrop-4707e'
const region = 'europe-west3'

const stage = 'PROD' // 'PROD' | 'DEV'

const baseURL = {
  "DEV": `http://localhost:5001/${projectName}/${region}/`,
  "PROD": `https://${region}-${projectName}.cloudfunctions.net/`
}

apiAxios.defaults.baseURL = baseURL[stage]
apiAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'