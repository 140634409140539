import React, { useState } from 'react'
import { Row } from 'reactstrap'
import CreateDrop from './CreateDrop'
import CreateDropButton from './CreateDropButton'


export default function Drops({drops}) {
  const [creatingNew, setCreatingNew] = useState(false)

  function toggleCreatingNew() {
    setCreatingNew(!creatingNew)
  }

  return (
    <div>
      
      {!drops.length ?
        <div className='mb-5 mt-5 text-center'>
          <span>GET STARTED</span>
          <h3>Create your first drop</h3>
        </div>
        :
        <div className="mb-3 font-weight-bold">
          Active drop requests
        </div>
      }

      <Row className='mb-3'>
        {
          creatingNew
            ? <CreateDrop setCreatingNew={toggleCreatingNew} />
            : <CreateDropButton setCreatingNew={toggleCreatingNew} />
        }
      </Row>

      {drops}

    </div>
  )
}