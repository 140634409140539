import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute'

export default (props) => {

  if (props.private) {    
    return (
      <PrivateRoute path={props.path} exact={props.exact}>
        <props.component />
      </PrivateRoute>
    )
  } else {
    return <Route path={props.path} component={props.component} exact={props.exact} />
  }
}