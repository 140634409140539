import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import deleteDrop from '../../../_Firestore/deleteDrop'

import './Trash.css'

export default function Deleted({ drops }) {
  const firestore = useFirestore()
  const { uid } = useSelector((state) => state.firebase.auth)

  drops.legth && console.log(drops[0].props);

  const deleteAll = () => {
    if (window.confirm('Are you sure you want to delete all Drops? This action cannot be undone!')) {
      drops.length && drops.map((drop, key) => {
        deleteDrop.hard(drop.props.drop_id, firestore, uid)
        return true
      })
    }
  }

  return (
    <div>

      <div className="mb-3 font-weight-bold d-flex drop-list-title-bar">
        <span>
          {drops.length ?
            "Trashed drop requests"
            :
            "Trash is empty"
          }
        </span>

        {drops.length ?
          <Button variant='link' onClick={deleteAll}>empty trash</Button>
          : ''
        }
      </div>

      {drops}

    </div>
  )
}