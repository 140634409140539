import { apiAxios } from './Axios'

export const dropGet = (params) => apiAxios.get('dropGet', { params: { ...params } })
  .then(res => res)

export const dropReply = (params) => apiAxios.get('dropReply', { params: { ...params } })
  .then(res => res)

export const dropRead = (params) => apiAxios.get('dropRead', { params: { ...params } })
  .then(res => res)
