import React, { useState } from "react"
import { useFirestore } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { v4 as uuidv4 } from 'uuid'
import { Row, Input, Card, CardBody, Button, Col } from 'reactstrap'

export default function CreateDrop(props) {
  const [presentDropTitle, setPresentDropTitle] = useState('')
  const [presentDropEmail, setPresentDropEmail] = useState('')
  const firestore = useFirestore()
  const { uid } = useSelector((state) => state.firebase.auth)

  const handleChange = ({ currentTarget: { name, value } }) => {
    if (name === "dropTitle") {
      setPresentDropTitle(value)
    }
    if (name === "dropEmail") {
      setPresentDropEmail(value)
    }
  }

  const createNewDrop = (title, email) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .add({
        title: title,
        recipient: email,
        urlKey: uuidv4(),
        created_at: Date.now(),
        opened_at: null,
        replied_at: null,
        payload: null,
        status: 2,
      })
      .then((docRef) => {
        docRef.update({
          drop_id: docRef.id,
        })
      })
    setPresentDropTitle('')
    setPresentDropEmail('')
    props.setCreatingNew()
  }

  return (
    <Col md={12}>

      <Card>
        <CardBody>
          <form action="">

            <Row>

              <Col md={12} lg={3}>
                <small className='text-muted'>Drop title</small>
                <Input type="text" name="dropTitle" value={presentDropTitle} onChange={handleChange} />
              </Col>

              <Col md={12} lg={3}>
                  <small className='text-muted'>To</small>
                  <Input type="email" name="dropEmail" value={presentDropEmail} onChange={handleChange} />
              </Col>

              <Col md={12} lg={3}>
                <Input type='text' style={{opacity: 0}} />
              </Col>

              <Col md={12} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>

                <Button className='mr-3'
                  disabled={presentDropTitle && presentDropEmail ? false : true}
                  color='secondary'
                  onClick={(e) => {
                    e.preventDefault();
                    createNewDrop(presentDropTitle, presentDropEmail)
                  }}
                >
                  <i className="fas fa-paper-plane mr-2"></i>Send
                </Button>

                <Button outline
                  color='secondary'
                  onClick={props.setCreatingNew}
                >
                  Cancel
                </Button>
              </Col>

            </Row>


          </form>
        </CardBody>
      </Card>
    </Col>
  )

}