import React, { useEffect, useState } from 'react'
import { Row, Card, CardBody, Col } from 'reactstrap'
import LoginOther from './Components/LoginOther'
import LoginForm from './Components/LoginForm'
import RegisterForm from './Components/RegisterForm'
import { Route, Switch, useHistory } from 'react-router-dom'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import logo from '../../picdrop-logo-dark.svg'
import Overlay from '../../Components/Overlay/Overlay'
import TextDivider from '../../Components/TextDivider/TextDivider'
import { userHomePath } from "../../routes";
import ForgotPasswordForm from './Components/ForgotPasswordForm'


export default function Login() {
  const [usingEmail, setUsingEmail] = useState(false)
  const [emailErr, setEmailErr] = useState(false)

  const [usingGoogle, setUsingGoogle] = useState(false)
  const [googleErr, setGoogleErr] = useState(false)

  const [usingFacebook, setUsingFacebook] = useState(false)
  const [facebookErr, setFacebookErr] = useState(false)

  const history = useHistory()
  const auth = useSelector(state => state.firebase.auth)

  const clearAllErrors = () => {
    setEmailErr(false)
    setGoogleErr(false)
    setFacebookErr(false)
  }

  const handleLogin = (type, action) => {

    clearAllErrors()

    switch (type) {
      case 'email':
        setUsingEmail(action)
        break;
      case 'google':
        setUsingGoogle(action)
        break;
      case 'facebook':
        setUsingFacebook(action)
        break;
      default:
        console.error('No login method specified calling handleLogin() in Login.js');
        break;
    }
  }

  const handleError = (type, message) => {
    switch (type) {
      case 'email':
        setEmailErr(message)
        break;
      case 'google':
        setGoogleErr(message)
        break;
      case 'facebook':
        setFacebookErr(message)
        break;
      default:
        console.error('No error type specified calling handleError() in Login.js');
        break;
    }
  }

  const loginProps = {
    userHomePath,
    handleLogin,
    handleError
  }

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      history.push(userHomePath)
    }
  }, [auth, history])

  return (
    <Row className="text-center justify-content-center">
      <Col xs={12} md={6} lg={4}>
        <Card>

          { usingEmail || usingGoogle || usingFacebook ?
            <Overlay />
            : ''
          }

          <CardBody className='pt-4'>
            <img src={logo} alt='' style={{ width: 120 }} />
          </CardBody>

          <CardBody className="border-top border-light">

            <LoginOther 
              variation='facebook'
              text='Continue with Facebook'
              {...loginProps}
              loggingIn={usingFacebook}
              error={facebookErr}
            />

            <LoginOther 
              variation='google'
              text='Continue with Google'
              {...loginProps}
              loggingIn={usingGoogle}
              error={googleErr}
              className='mt-3'
            />

            <TextDivider text='or' className="text-muted" />

            <Switch>
              <Route exact path='/login'>
                <LoginForm
                  {...loginProps}
                  loggingIn={usingEmail}
                  error={emailErr}
                />
              </Route>
              <Route exact path='/register'>
                <RegisterForm
                  {...loginProps}
                  loggingIn={usingEmail}
                  error={emailErr}
                />
              </Route>
              <Route exact path='/forgotPassword'>
                <ForgotPasswordForm
                  {...loginProps}
                  loggingIn={usingEmail}
                  error={emailErr}
                />
              </Route>
            </Switch>

          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
