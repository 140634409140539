import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import Moment from 'react-moment'
import DropItemMenu from "./DropItemMenu"
import { Input, InputGroup } from "reactstrap"
import { Row, Col, Card, Collapse } from 'react-bootstrap';
import StatusIcon from "./DropItemIcons"
import DropItemShareButton from "../DropItemShareButton/DropItemShareButton"


export default function DropItem(props) {
  const { uid } = useSelector((state) => state.firebase.auth)
  const [isExpanded, setIsExpanded] = useState(false)
  const dropUrlRef = useRef(null)
  const dropPayloadRef = useRef(null)

  const dropUrl = window.location.origin + '/s/' + uid + '.' + props.drop_id + '.' + props.urlKey


  return (
    <Row className={`mb-3`}>
      <Col sm={12}>
        <Card className={`drop-item ${isExpanded && 'drop-expanded'}`}>

          <Card.Body onClick={() => setIsExpanded(!isExpanded)}
            aria-controls={props.drop_id + "-collapse-info"}
            aria-expanded={isExpanded}
            style={{
              cursor: 'pointer'
            }}
            className={isExpanded && 'bg-light'}
          >
            <Row>
              <Col sm={6} md={4} lg={3}>
                <small className="text-muted">Drop title</small>
                <div>
                  {props.title}
                </div>
              </Col>
              <Col md={4} lg={3} className="d-none d-md-block">
                <small className="text-muted">To</small>
                <div>
                  {props.recipient}
                </div>
              </Col>
              <Col lg={3} className="d-none d-lg-block">
                <small className="text-muted">Sent</small>
                <div>
                  <Moment fromNow unix>{Math.floor(props.created_at / 1000)}</Moment>
                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <small className="text-muted">Status</small>
                <div>
                  <StatusIcon variant='open' value={props.opened_at} className='mr-2' />
                  <StatusIcon variant='reply' value={props.payload} />
                </div>
              </Col>
            </Row>

            <DropItemMenu
              visible={isExpanded}
              uid={uid}
              {...props}
            />
          </Card.Body>

          <Collapse in={isExpanded}>
            <div id={props.drop_id + "-collapse-info"}>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <small className="text-muted">Drop url</small>
                    <InputGroup>
                      <Input readOnly
                        type={'text'}
                        value={dropUrl}
                        innerRef={dropUrlRef}
                      />
                      <DropItemShareButton
                        shareData={{
                          title: `PicDrop - ${props.title}`,
                          url: dropUrl
                        }}
                      />
                      <a href={dropUrl} target='_blank' rel="noopener noreferrer" role="button" className="btn btn-secondary">
                        <i className="fas fa-external-link-alt"></i>
                      </a>
                    </InputGroup>
                  </Col>
                </Row>
              </Card.Body>

              {props.payload ?
                <Card.Body>
                  <small className="text-muted">Response</small>
                  <InputGroup>
                    <Input readOnly
                      type='textarea'
                      value={props.payload}
                      innerRef={dropPayloadRef}
                    />
                    <DropItemShareButton
                      copyOnly
                      shareData={{
                        text: props.payload
                      }}
                    />
                  </InputGroup>
                </Card.Body>
                :
                ''
              }
            </div>
          </Collapse>

        </Card>
      </Col>
    </Row>
  )
}