import React from 'react'
import { Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import Header from './Components/Header'
import { routes } from './routes'
import AppRoute from './Components/AppRoute/AppRoute'


const Template = () => {
  return (
    <>
      <Header />

      <Container id="content">

        <Switch>

          {Object.keys(routes).map((routeKey, key) => {
            return <AppRoute key={key} path={routes[routeKey].path} component={routes[routeKey].component} exact={routes[routeKey].exact} private={routes[routeKey].private} />
          })}

        </Switch>

      </Container>

      <div className="footer-push"></div>
    </>
  )
}

export default Template