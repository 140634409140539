export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '200px 50px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#aaaaaa',
  outline: 'none',
  transition: 'all .24s ease-in-out',
};

export const activeStyle = {
  borderColor: '#2196f3',
  color: '#000',
  backgroundColor: '#fcfcfc',
};

export const acceptStyle = {
  borderColor: '#00e676',
  color: '#000',
  backgroundColor: '#fcfcfc',
};

export const rejectStyle = {
  borderColor: '#ff1744'
};