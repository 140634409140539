import React from 'react'

export default function Deleted({ drops }) {

  return (
    <div>

      {drops.length ?
        <div className="mb-3 font-weight-bold">
          Archived drop requests
        </div>
        :
        <div className="mb-3 font-weight-bold">
          No archived drop requests
        </div>
      }

      {drops}

    </div>
  )
}