import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { isLoaded, isEmpty } from "react-redux-firebase"
import { useSelector } from "react-redux"

export default function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)

  // console.log(auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
