import React from 'react'
import { Col, Card, CardBody, CardText } from 'reactstrap'

import './CreateDropButton.css'

export default function CreateDropButton(props) {
    return (
            <Col md={12}>
                <Card onClick={props.setCreatingNew} className='create-drop-button'>
                    <CardBody>
                        <CardText className="text-center">
                            CREATE DROP
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
    )
}