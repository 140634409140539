import React from 'react'

export default function Footer() {
  return (
    <div className='footer-inner'>
      <small>
        {'created with ❤️ by '}
        <a href='https://sotiriskipouros.com' target='_blank' rel="noopener noreferrer">
          Sotiris Kipouros
        </a>
      </small>
    </div>
  )
}