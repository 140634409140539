import React from 'react'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useState } from 'react'
import { useFirestore } from "react-redux-firebase"
import { useSelector } from "react-redux"
import SetDrop from '../../../../_Firestore/setDrop'
import DeleteDrop from '../../../../_Firestore/deleteDrop'

import './DropItemMenu.css'

export default function DropItemMenu(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const firestore = useFirestore()
  const { uid } = useSelector((state) => state.firebase.auth)

  const handleMenuClick = (e) => {
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
  }

  const setStatus = (status) => {
    SetDrop.status(props.drop_id, status, firestore, uid)
  }

  const setOpenedAt = (opened_at) => {
    SetDrop.opened_at(props.drop_id, opened_at, firestore, uid)
  }

  const setPayload = (payload) => {
    SetDrop.payload(props.drop_id, payload, firestore, uid)
  }

  const deleteDrop = () => {
    DeleteDrop.hard(props.drop_id, firestore, uid)
  }

  return (
    <ButtonDropdown
      className={`drop-item-menu-button-wrapper ${props.visible ? 'visible' : 'hidden'}`} // Set menu button visible when drop is open
      isOpen={dropdownOpen}
      toggle={handleMenuClick}
    >
      <DropdownToggle color='light'
        className='drop-item-menu-button'
      >
        <i className="fas fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu right>

        {props.opened_at ?
          <DropdownItem onClick={() => setOpenedAt(null)}><i className="far fa-eye-slash mr-2"></i>Mark as unread</DropdownItem>
          :
          <DropdownItem onClick={() => setOpenedAt(Date.now())}><i className="far fa-eye mr-2"></i>Mark as read</DropdownItem>
        }
        {props.payload &&
          <DropdownItem onClick={() => setPayload(null)}><i className="fas fa-broom mr-2"></i>Remove response</DropdownItem>
        }

        <DropdownItem divider />

        {props.status > 1 &&
          <DropdownItem onClick={() => setStatus(1)}><i className="fas fa-inbox mr-2"></i>Archive drop</DropdownItem>
        }
        {props.status === 1 &&
          <DropdownItem onClick={() => setStatus(2)}><i className="fas fa-inbox mr-2"></i>Move to active drops</DropdownItem>
        }
        {props.status === 0 &&
          <DropdownItem onClick={() => setStatus(2)}><i className="fas fa-trash-restore mr-2"></i>Restore drop</DropdownItem>
        }
        {props.status >= 1 &&
          <DropdownItem onClick={() => setStatus(0)}><i className="fas fa-trash mr-2"></i>Move to trash</DropdownItem>
        }


        {props.status <= 0 &&
          <>
            <DropdownItem divider />

            <DropdownItem onClick={deleteDrop} className='text-danger'><i className="fas fa-ban mr-2"></i>Delete drop</DropdownItem>
          </>
        }

      </DropdownMenu>
    </ButtonDropdown>
  )
}