import React from 'react'
import './Copied.css'
import { Badge } from 'reactstrap'

export default function Copied(props) {

  const visible = props.visible ? ' copied-visible' : ''

  const text = props.text ? props.text : 'Copied!'
  const gap = props.gap ? props.gap : 10
  const snapToMy = props.snapToMy ? props.snapToMy : 'left'
    
  const position = {
    left: { left: gap },
    right: { right: gap }
  }

  return (
    <div className="copied-outer">
      <Badge color="dark" pill className={"copied" + visible} style={position[snapToMy]} >{text}</Badge>
    </div >
  )
}