import React from 'react'
import { useForm, Controller } from "react-hook-form"
import { CardText, Input, Button, Spinner } from 'reactstrap'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'

export default function RegisterForm(props) {
  const { control, handleSubmit } = useForm()
  const firebase = useFirebase()
  const history = useHistory()

  const createNewUser = ({ displayName, registerEmail, registerPassword1, registerPassword2 }) => {
    if (registerPassword1 && registerPassword1 === registerPassword2) {
      props.handleLogin('re', true)
      firebase
        .createUser({
          email: registerEmail,
          password: registerPassword1,
          displayName: displayName
        })
        .then((userData) => {
          props.handleLogin('email', false)
          history.push(props.userHomePath)
        })
        .catch((err) => {
          props.handleLogin('email', false)
          props.handleError('email', err.message)
        })
    } else {
      props.handleError('email', 'The two passwords does not match')
    }
  }

  return (
    <form onSubmit={handleSubmit(createNewUser)}>
      <CardText>
        <Controller as={Input} name='displayName' control={control} defaultValue="" placeholder='Name' type='text' />
      </CardText>
      <CardText>
        <Controller as={Input} name='registerEmail' control={control} defaultValue="" placeholder='Email' type='email' />
      </CardText>
      <CardText>
        <Controller as={Input} name='registerPassword1' control={control} defaultValue="" placeholder='Password' type='password' />
      </CardText>
      <CardText>
        <Controller as={Input} name='registerPassword2' control={control} defaultValue="" placeholder='Repeat password' type='password' />
      </CardText>
      <CardText>
        <small className='text-danger'>{props.error}</small>
      </CardText>
      <CardText>
        <Button block color='secondary'>
          {props.loggingIn ?
            <Spinner size="sm" color="light" />
            :
            <>
              <i className='fas fa-envelope' style={{ marginRight: 10 }}></i>
              CREATE ACCOUNT
            </>
          }
        </Button>
      </CardText>
      <CardText>
        <small className="text-muted"><em> Already have an account? <Link to='/login'>Sign in</Link></em></small>
      </CardText>
    </form>
  )
}