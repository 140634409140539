import React from 'react'
import { useForm, Controller } from "react-hook-form"
import { CardText, Input, Button, Spinner } from 'reactstrap'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'

export default function LoginFormEmail(props) {
  const { control, handleSubmit } = useForm()
  const firebase = useFirebase()
  const history = useHistory()

  const loginUser = ({ loginEmail, loginPassword }) => {
    props.handleLogin('email', true)
    firebase
      .login({
        email: loginEmail,
        password: loginPassword
      })
      .then(() => {
        props.handleLogin('email', false)
        history.push(props.userHomePath)
      })
      .catch((err) => {
        props.handleLogin('email', false)
        props.handleError('email', err.message)
      })
  }

  return (
    <form onSubmit={handleSubmit(loginUser)}>
      <CardText>
        <Controller as={Input} name='loginEmail' control={control} defaultValue="" placeholder='Email' type='email' />
      </CardText>
      <CardText>
        <Controller as={Input} name='loginPassword' control={control} defaultValue="" placeholder='Password' type='password' />
        <div className="text-small text-muted text-right">
          <small><a href="/passwordReset">Forgot password?</a></small>
        </div>
      </CardText>
      <CardText>
        <small className='text-danger'>{props.error}</small>
      </CardText>
      <CardText>
        <Button block color='secondary'>
          {props.loggingIn ?
            <Spinner size="sm" color="light" />
            :
            <>
              <i className='fas fa-sign-in-alt' style={{ marginRight: 10 }}></i>
              Sign in with email
            </>
          }
        </Button>
      </CardText>
      <CardText>
        <small className="text-muted"><em>Don't have an account? <Link to='/register'>Create one</Link></em></small>
      </CardText>
    </form>
  )
}