import React from 'react'
import style from './TextDivider.module.css'

export default function TextDivider({ text, tagname, className, ...rest }) {

  const Tagname = tagname || 'span'
  const innerText = text || ''
  const additionalClassName = className ? ' ' + className : ''

  return (
    <div className={ style.wrapper + additionalClassName }>
      <Tagname className={ style.inner }>
        { innerText }
      </Tagname>
    </div>
  )
}