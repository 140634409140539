import React from 'react'
import './UserCheck.css'

export default function UserCheck() {

  return (
    <div className="check-user-wrapper">
      <div className="check-user-inner">
        <h3>Loading user data</h3>
      </div>
    </div>
  )
}