import React from 'react'

const statusIconMap = {
  'OPEN': {
    classes: 'fas fa-eye',
    style: {}
  },
  'NOT_OPEN': {
    classes: 'far fa-eye-slash text-muted',
    style: {opacity: 0.4}
  },
  'REPLIED': {
    classes: 'fas fa-reply text-success',
    style: {}
  },
  'NOT_REPLIED': {
    classes: 'fas fa-reply text-muted',
    style: {opacity: 0.4}
  },
}

export default function StatusIcon(props) {

  let tag = ''
  switch (props.variant) {
    case 'open':
      tag = props.value ? 'OPEN' : 'NOT_OPEN'
      break;

    case 'reply':
      tag = props.value ? 'REPLIED' : 'NOT_REPLIED'
      break;

    default:
      break;
  }  

  const classes = props.className ? statusIconMap[tag].classes + ' ' + props.className : statusIconMap[tag].classes

  return (
    <i className={classes} style={statusIconMap[tag].style}></i>
  )
}