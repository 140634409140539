const deleteDrop = {
  soft: (drop_id, status, firestore, uid) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .doc(drop_id)
      .update({
        status
      })
  },
  hard: (dropId, firestore, uid) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .doc(dropId)
      .delete()
  }
}

export default deleteDrop