import React from 'react'
import { useSelector } from 'react-redux'
import Gravatar from 'react-gravatar'


export default function ProfilePic(props) {
  const auth = useSelector((state) => state.firebase.auth)
  
  const email = auth.email || ''
  const photoURL = auth.photoURL || ''

  // OPTIONAL props
  const circular = props.circular || true // Default: true (renders circular picture)
  const size = props.size || 24 // Default 24 (pixels)
  const style = props.style || {}

  // Under the hood calculations
  const borderRadius = circular ? '50%' : 0

  return (
    <>
      {photoURL ?
        <img src={photoURL} alt=''
          style={{
            width: size,
            borderRadius: borderRadius,
            ...style
          }}
        />
        :
        <Gravatar
          email={email}
          size={size}
          default="mp"
          style={{
            borderRadius: borderRadius,
            ...style
          }}
        />
      }
    </>
  )
}