import React from 'react'
import Template from "./Template"
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import UserCheck from './Components/UserCheck/UserCheck'

function App() {
  const auth = useSelector(state => state.firebase.auth)
  
  return (
    <>
    {
      !isLoaded(auth)
        // true
        ?
        <UserCheck />
        :
        <Template />
    }
    </>
  )
}

export default App