import React from 'react'

const style = {
    appHeader: {
        display: 'flex',
        padding: '40px',
        flexDirection: 'column',
    }
}

export default function Header() {
    return (
        <div style={style.appHeader}>
        </div>
    )
}