import React, { useEffect, useState, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import cssModule from './Dropzone.module.css'
import { Button, Input, Spinner, Col } from 'reactstrap'
import { getCompoundKey } from '../../Components/drop-url-splitter'
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from './Dropzone.style'
import { dropGet, dropReply, dropRead } from '../../Api/Drops'
import Moment from 'react-moment'
import { Modal } from 'react-bootstrap'

export default function Previews(props) {
  const [currentDrop, setCurrentDrop] = useState(null)
  const [files, setFiles] = useState([])
  const [sendingSelection, setSendingSelection] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  // console.log(currentDrop);

  const compoundKey = getCompoundKey(window.location.pathname)

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragActive, isDragReject]);

  const thumbs = files.map(file => (
    <div className={cssModule.dropzone_thumb} key={file.name}>
      <div className={cssModule.dropzone_thumb_inner}>
        <div className={cssModule.dropzone_thumb_inner_div}>
          <img src={file.preview} alt="" className={cssModule.dropzone_thumb_inner_img} />
        </div>
      </div>
    </div>
  ));

  const deleteSelection = () => {
    window.confirm('Θέλεις σίγουρα να διαγράψεις την επιλογή σου;') && setFiles([])
  }

  const listToString = filesList => {
    let result = ''
    // eslint-disable-next-line array-callback-return
    filesList.map(function (item, i) {
      let filename = { ...item }.path.split('/').reverse()[0]
      filename = filename.replace(/.jpg|.png|.nef/gmi, "") // removes file extensions
      filename += ',' // comma separates filenames
      result += filename
    })
    return (result.replace(/,$/gmi, ''))
  }

  const handleSend = async () => {
    setSendingSelection(true)
    await dropReply({
      key: compoundKey,
      payload: listToString(files)
    })
      .then(() => {
        setSendingSelection(false)
        setModalShow(true)
      })
  }

  // if drop.opened_at is null, mark drop as viewed...
  if (currentDrop) {
    if (!currentDrop.opened_at) {
      dropRead({
        key: compoundKey,
        opened_at: Date.now()
      })
    }
  }

  useEffect(() => {
    async function getDropData(key) {
      const dropData = await dropGet({ key })
      setCurrentDrop(dropData.data)
    }
    getDropData(compoundKey)
  }, [compoundKey])

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview))
    }
  })


  const selectedFilesNumber = files.length

  if (!currentDrop) {
    return (
      <Spinner style={{
        display: "block",
        margin: "0 auto"
      }} />
    )
  }

  if (currentDrop.status < 2) {
    return (
      <div style={{
        textAlign: "center"
      }}
      >
        <h3>
          You don't have the permission to view this page.
        </h3>
        Contact your photographer if you believe this is a mistake.
      </div>
    )

  }

  return (
    <section className="container">

      <div className='mb-4'>
        {currentDrop ?
          <div>
            <h4 className='mb-1'>{currentDrop.title}</h4>
            <small className='text-muted'>{'Sent '}
              <Moment fromNow unix>{Math.floor(currentDrop.created_at / 1000)}</Moment>
            </small>
          </div>
          :
          <Spinner />
        }
      </div>

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className='text-center'>Σύρε εδώ τις φωτογραφίες που έχεις επιλέξει ή κάνε κλικ για επιλογή.</p>
      </div>
      <div className={cssModule.toolbar}>

        <Col xs={12} sm={12} md={6} className="toolbar-left mb-2">
          <span>Επιλεγμένες εικόνες: {selectedFilesNumber}</span>
        </Col>

        {selectedFilesNumber > 0 &&
          <Col xs={12} sm={12} md={6} className="toolbar-right">
            <Button color="secondary" className='mr-2' onClick={handleSend} disabled={sendingSelection} >
              {sendingSelection && <Spinner size="sm mr-2" />}
              Αποστολή επιλογής
              <i className="fas fa-paper-plane ml-2" />
            </Button>
            <Button outline color="danger" onClick={deleteSelection}><i className="fa fa-trash-alt" /></Button>
          </Col>
        }

      </div>
      {
        false ?
          <Input
            type='textarea'
            rows={4}
            readOnly
            value={listToString(files)}
          /> : ''
      }

      {/* Thumbnails */}
      <aside className={cssModule.thumbsContainer}>
        {thumbs}
      </aside>

      {/* Modal - Response sent */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ textAlign: "center" }}>Όλα έτοιμα!</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img style={{ width: "100%" }} src='\images\mail-download-white.gif' alt='Envelope flying to its destination' />
          <p style={{ textAlign: "center" }}>Η επιλογή σου έρχεται!</p>
          <p style={{ textAlign: "center" }}>Μπορείς να κλείσεις αυτό το παράθυρο και να περιμένεις νέα μου.</p>
        </Modal.Body>
      </Modal>

    </section >
  );
}