const setDrop = {
  status: (drop_id, status, firestore, uid) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .doc(drop_id)
      .update({
        status
      })
  },
  opened_at: (dropId, opened_at, firestore, uid) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .doc(dropId)
      .update({
        opened_at
      })
  },
  payload: (dropId, payload, firestore, uid) => {
    firestore
      .collection("users")
      .doc(uid)
      .collection("drops")
      .doc(dropId)
      .update({
        payload
      })
  }
}

export default setDrop