import React from "react"
import ReactDOM from "react-dom"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import { firebaseConfig } from "./firebaseConfig"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import { rootReducer } from "./ducks/reducers"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import Footer from "./Components/Footer/Footer"

// Imports bootstrap scss along with our custom variables
import "./picdrop-bootstrap.scss"
// Css tweaks for bootstrap (TODO: convert to scss)
import "./picdrop-bootstrap-override.css"
// Global css
import "./index.css"


const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
}

const initialState = {};
const store = createStore(rootReducer, initialState)

firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.analytics()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, //since we are using Firestore
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

ReactDOM.render(
  <Footer />,
  document.getElementById("footer")
)
